import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { InstagramEmbed } from 'react-social-media-embed';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';

const Ultimipost = () => {
  return (
    <div>
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <div>
          <motion.h1 
            className="text-center mb-4" 
            style={{ fontWeight: 'bold', fontFamily: 'Inter, sans-serif', fontSize: '40px' }}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            ⚠️ULTIMI POST DI NGL CILA⚠️
          </motion.h1>
          <Row className="justify-content-center">
            {['C_fqZpxtrNZ', 'C_ObTRuMmuT', 'C_vI4jrsyQE'].map((postId, index) => (
              <Col xs={12} sm={12} md={12} lg={4} className="mb-4 d-flex justify-content-center" key={index}>
                <motion.div 
                  className="shadow-sm p-3 mb-5 bg-white rounded" 
                  style={{ maxWidth: '400px', minHeight: '500px' }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <InstagramEmbed
                    url={`https://www.instagram.com/p/${postId}/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==`}
                    width="100%"
                    height="auto"
                    style={{ borderRadius: '8px' }}
                    hidecaption={true}
                    title={`Instagram${index + 1}`}
                    options={{ hidecaption: true, omitscript: true }}
                  />
                </motion.div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      <style jsx>{`
        @media (max-width: 1200px) {
          .vh-100 {
            height: auto !important;
          }
          .mb-4 {
            margin-bottom: 2rem !important;
          }
          .col-12 {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        @media (min-width: 1201px) {
          .col-lg-4 {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
          }
        }
      `}</style>
    </div>
  );
};

export default Ultimipost;

















































