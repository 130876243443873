import React from "react";
import { ChakraProvider, Box, Heading, Text, Button, Stack, Link } from "@chakra-ui/react";
import { FaInstagram, FaWhatsapp, FaNewspaper, FaPen } from "react-icons/fa";
import Typical from 'react-typical';
import back from './contents/background.jpg';
import { ReactComponent as Anologo }  from './contents/anonymouss.svg';
import { ReactComponent as Ngllogo } from './contents/nglicon.svg';

function Buttones() {
    return (
        <div style={{backgroundImage: `url(${back})`}}>
            <ChakraProvider>
                <Box
                    textAlign={{ base: "center", md: "left" }}
                    p={8}
                    bgImage={`url(${back})`}
                    bgSize="cover"
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    color="white"
                    position="relative"
                    _before={{
                        content: '""',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Overlay scuro
                        backdropFilter: "blur(3px)", // Riduce la sfocatura
                        zIndex: 0,
                    }}
                >
                    <Box position="relative" zIndex={1}>
                        <Heading as="h1" size="2xl" mb={12} fontFamily="Arial, sans-serif" letterSpacing="widest" className="animated-heading">
                            NGL CILA, IL REGNO <br /> DEI GOSSIP LOCALI
                        </Heading>
                        <Text fontSize="lg" mb={8} fontStyle="italic" className="typing-text" fontFamily="Inter, sans-serif">
                            <Typical
                                steps={['Esplora i Gossip di Cila', 2000, 'Guarda le ultime notizie', 2000, 'Visita il nostro sito web per attività interattive', 2000, 'Partecipa ai nostri quiz', 2000, 'Vota ai nostri sondaggi', 2000, 'Scrivi un Ngl', 2000, 'Leggi la scienza semplicemente con NGLPop!', 2000, 'Guarda le votazioni', 2000, 'Interagisci con noi', 2000, 'Partecipa ai nostri eventi', 2000]}
                                loop={Infinity}
                                wrapper="span"
                            />
                        </Text>
                        <Stack direction={{ base: "column", md: "row" }} spacing={4} mt={8} align="center" justify="center">
                            <Link href="https://www.instagram.com/ngl_cila" isExternal>
                                <Button leftIcon={<FaInstagram />} colorScheme="pink" variant="solid" borderRadius="full" size="md" flex="1" minWidth="250px" className="animated-button">
                                    Seguici su Instagram
                                </Button>
                            </Link>
                            <Link href="https://ngl.link/ngl_cila25460" isExternal>
                                <Button leftIcon={<Ngllogo  style={{ width: '30px', height: '30px', fill: 'white' }}/>} colorScheme="red" variant="solid" borderRadius="full" size="md" flex="1" minWidth="250px" className="animated-button ">
                                    Scrivi un NGL
                                </Button>
                            </Link>
                            <Link href="https://www.whatsapp.com" isExternal>
                                <Button leftIcon={<FaWhatsapp />} colorScheme="green" variant="solid" borderRadius="full" size="md" flex="1" minWidth="250px" className="animated-button">
                                    Iscriviti al gruppo WhatsApp
                                </Button>
                            </Link>
                            <Link href="/news" isExternal>
                                <Button leftIcon={<FaNewspaper />} colorScheme="whiteAlpha" variant="solid" borderRadius="full" size="md" flex="1" minWidth="250px" className="animated-button">
                                    Visita le notizie
                                </Button>
                            </Link>
                            <Link href="https://anonymouss.me/ngl_cila" isExternal>
                                <Button leftIcon={<Anologo  style={{ width: '30px', height: '30px', fill: 'white' }}/>} colorScheme="whiteAlpha" variant="solid" borderRadius="full" size="md" flex="1" minWidth="250px" className="animated-button">
                                    Invia uno segnale ad Anonymouss
                                </Button>
                            </Link>
                        </Stack>
                    </Box>
                </Box>
                <style jsx>{`
                    @media (max-width: 768px) {
                        .chakra-heading {
                            font-size: 1.5rem;
                            margin-bottom: 1rem;
                        }
                        .chakra-text {
                            font-size: 1rem;
                            margin-bottom: 1rem;
                        } 
                        
                        @keyframes fadeIn {
                            0% { opacity: 0; transform: translateY(-20px); }
                            100% { opacity: 1; transform: translateY(0); }
                        }
                        
                        .animated-button {
                            transition: transform 0.2s, background-color 0.2s;
                        }
                        .animated-button:hover {
                            transform: scale(1.1);
                            background-color: #ffeb3b;
                        }
                    }
                    .chakra-button {
                        color: #000;
                        background-color: #fff;
                    }
                    @media (min-width: 992px) {
                        .chakra-button {
                            color: #000;
                            background-color: #fff;
                            border: 2px solid #000;
                        }
                        .chakra-button:hover {
                            background-color: #f0f0f0;
                        }
                        .chakra-heading {
                            color: #fff;
                            margin: 120px 0 195px 120px;
                            font-size: 70px;
                            font-family: Arial, sans-serif;
                        }
                        .chakra-text {
                            color: #fff;
                            font-size: 25px;
                            font-family: Inter, sans-serif;
                            margin: 0 auto;
                            margin-left: 120px; /* Aggiunto margine sinistro */
                        }
                        .animated-heading {
                            animation: fadeIn 2s ease-in-out;
                        }
                        .typing-text {
                            animation: typing 4s steps(40, end), blink-caret .75s step-end infinite;
                            white-space: nowrap;
                            font-size: 50px;
                            overflow: hidden;
                            border-right: .15em solid orange;
                            margin-left: 120px; /* Aggiunto margine sinistro */
                        }
                        .animated-button {
                            transition: transform 0.2s, background-color 0.2s;
                        }
                        .animated-button:hover {
                            transform: scale(1.1);
                            background-color: #ffeb3b;
                        }
                        @keyframes fadeIn {
                            0% { opacity: 0; transform: translateY(-20px); }
                            100% { opacity: 1; transform: translateY(0); }
                        }
                        @keyframes typing {
                            from { width: 0; }
                            to { width: 100%; }
                        }
                        @keyframes blink-caret {
                            from, to { border-color: transparent; }
                            50% { border-color: orange; }
                        }
                    }
                `}</style>
            </ChakraProvider>
        </div>
    );
}

export default Buttones;
































