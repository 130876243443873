import React, { useState } from 'react';
import { Form, Button, Alert, Container, Image } from 'react-bootstrap';
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from './js/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Navbarngl from './navbarngl';
import Tops from './top';
import ngllogo from './contents/ngllogo.jpg';

const LoginUser = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, usernameOrEmail, password);
      const user = userCredential.user;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        // Gestisci il login e la navigazione
      } else {
        setError('Nome utente o password errati');
      }
    } catch (error) {
      setError('Errore nel login: ' + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', user.uid), {
          username: user.displayName,
          email: user.email
        });
      }
      // Gestisci il login e la navigazione
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div style={{ backgroundColor: '#f8f9fa', fontFamily: 'Inter, sans-serif', minHeight: '100vh' }}>
      <Navbarngl />
      <Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Image src={ngllogo} rounded style={{ maxWidth: '150px', marginBottom: '20px' }} alt="Logo" />
        <Form onSubmit={handleLogin} className="w-100" style={{ maxWidth: '400px' }}>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="formBasicUsernameOrEmail">
            <Form.Label>Nome Utente o Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Inserisci nome utente o email"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              aria-label="Nome Utente o Email"
              required
              style={{ fontSize: '1.2rem', borderRadius: '10px', marginBottom: '10px' }}
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              aria-label="Password"
              required
              style={{ fontSize: '1.2rem', borderRadius: '10px', marginBottom: '20px' }}
            />
          </Form.Group>
          <Button variant="primary" type="submit" style={{ width: '100%', padding: '0.75rem', fontSize: '1.2rem', borderRadius: '10px' }}>
            Accedi
          </Button>
          <Button
            variant="light"
            onClick={handleGoogleLogin}
            style={{ width: '100%', padding: '0.75rem', fontSize: '1.2rem', borderRadius: '10px', marginTop: '10px', marginBottom: '10px' }}
          >
            Accedi con Google
          </Button>
          <Button
            variant="link"
            onClick={() => navigate('/register')}
            style={{ width: '100%', fontSize: '1rem', textDecoration: 'none', textAlign: 'center' }}
          >
            Non ti sei ancora registrato? Registrati
          </Button>
        </Form>
      </Container>
      <Tops />
    </div>
  );
};

export default LoginUser;









