
import { Link, Routes, Route, Navigate } from 'react-router-dom';
import Parse from 'parse';
import Home from './components/home';
import News from './components/newscila';
import Modulo from './components/modulo';
import Minigioco from './components/minigioco';
import Football from './components/football';
import MessaggioDalServer from './components/testserver';
import Chisiamo from './components/chisiamo';
import Wiki from './components/wiki';
import People from './components/people';
import Login from './components/login';
import Dashboard from './components/daschboard';
import Cannabis from './components/effetticannabis';
import Eruzioneoro from './components/eruzioneoro';
import Archiviofoto from './components/archiviofoto';
import Autostima from './components/autostima';
import CasaBianca from './components/casabianca';
import Scoregge from './components/Scoregge';
import { NextUIProvider } from '@nextui-org/react';
 



import NotizieVige from './components/NotizieVige';
import NotFound from './components/NotFound';
import PrivacyPolicy from './components/gdpr';
import ReteCiclovaria from './components/reteciclovaria';
import Notegold from './components/notegold';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';
import './App.css'
import ModelViewer from './components/modelviewer';
import Creazionevideogiochi from './components/creazionevidogiochi';
import CilaStudios from './components/cilastudios';
import Acquistomagliette from './components/acquistomagliette';
import Eventi from './components/eventi';
import Orarioautoguidovie from './components/orarioautoguidovie';
import { ChakraProvider } from '@chakra-ui/react';
import DownloadPage from './components/downloadapp';
import Triangolobermuda from './components/triangolobermuda';
import Loginuser from './components/loginuser';
import RegistrationForm from './components/register';
import { UserProvider } from './components/js/usercontext';
import { Shop } from '@material-ui/icons';
import Shopss from './components/shop';
import Anonymus from './components/anonymous';
import HomeNglVige from './components/homenglvige';

// Assicurati di inizializzare Parse con le tue credenziali di Back4App
const PARSE_APPLICATION_ID = 'h6n3hXeAyPq4Q9VYaiI1n5FnRIxoeCdlrynUbAMA';
const PARSE_JAVASCRIPT_KEY = 'Ts74nlTiApt0TLezItum716SuEnWEIsujWFpjsct';
const PARSE_HOST_URL = 'https://parseapi.back4app.com';

Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

Parse.LiveQuery.on('open', () => {
  console.log('LiveQuery connection opened');
});

Parse.LiveQuery.on('close', () => {
  console.log('LiveQuery connection closed');
});

Parse.LiveQuery.on('error', (error) => {
  console.error('LiveQuery error:', error);
});

function App() {
  return (
    
    <Routes>
      <Route path='/home' element={<Home />} />
      <Route path='/' element={<Navigate to="/home" />} />
      <Route path='/news' element={<News />} />
      <Route path='/modulo' element={<Modulo />} />
      <Route path='/minigioco' element={<Minigioco />} />
      <Route path='/calcio' element={<Football />} />
      <Route path='/testserver' element={<MessaggioDalServer />} />
      <Route path='/chisiamo' element={<Chisiamo />} />
      <Route path='/people' element={<People />} />
      <Route path='/wiki' element={<Wiki />} />
      <Route path='/login' element={<Login />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/gli-effetti-della-cannabis' element={<Cannabis />} />
      <Route path='/vulcano-che-erutta-oro' element={<Eruzioneoro />} />
      <Route path='/archiviofoto' element={<Archiviofoto />} />
      <Route path='/cos-e-l-autostima' element={<Autostima />} />
      <Route path='/com-e-fatta-la-casa-bianca' element={<CasaBianca />} />
      <Route path='/cosa-sono-le-flautlenze' element={<Scoregge />} />
      <Route path='/notizievige' element={<NotizieVige />} />      
      <Route path='/gdpr' element={<PrivacyPolicy />} />
      <Route path='/ciclovie' element={<ReteCiclovaria />} />
      <Route path='/notegold' element={<Notegold />} />
      <Route path='/visualizzatore' element={<ModelViewer />} />
      <Route path='/come-vengono-creati-i-videogiochi' element={<Creazionevideogiochi />} />
      <Route path='/cilastudios' element={<CilaStudios />} />
      <Route path='/acquistomagliette' element={<Acquistomagliette />} />
      <Route path='/eventi' element={<Eventi />} />
      <Route path='/orarioautoguidovie' element={<Orarioautoguidovie />} />
      <Route path='/download' element={<DownloadPage />} />
      <Route path='/triangolo-delle-bermuda' element={<Triangolobermuda />} />
      <Route path='/login-auth' element={<Loginuser />} />
      <Route path='/register' element={<RegistrationForm />} />
      <Route path='/shop' element={<Shopss/>} />
      <Route path='/private-anonymous' element={<Anonymus/>} />
      <Route path='/nglvige' element={<HomeNglVige/>} />
      <Route path='*' element={<NotFound />} />
    </Routes>
    
  );
}

export default App;
