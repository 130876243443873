import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa";
import icona from './contents/favicon.png';
import icona2 from './contents/logospotted.jpg';
import icona3 from './contents/image3.jpg'
import logo4 from './contents/logo4.jpg';
import logo5 from './contents/logo5.jpg';
import logo6 from './contents/logo6.jpg';

function Partnership() {
    const partners = [
        {
            name: "Roggia Fanpage",
            description: "Roggia Fanpage è un profilo Instagram che fotografa corsi d'acqua e ambienti acquatici.",
            logo: icona,
            link: "https://instagram.com/_roggia_fanpage_"
        },
        {
            name: "Spotted dell' ITIS Caramuel",
            description: "Il profilo gossip e notizie ufficiale del ITIS Caramuel di Vigevano, che collabora con noi e il nostro team.",
            logo: icona2,
            link: "https://instagram.com/spotted.caramuel_"
        },
        {
            name: "Vige Question",
            description: "Un nuovo profilo gossip di Vigevano, che ha appena iniziato la sua aventura e collabora con il nostro team su argomenti utili.",
            logo: icona3,
            link: "https://www.instagram.com/vige_question?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        },
        {
            name: "Gossip Bramante",
            description: "Un nuovo profilo gossip di Vigevano, della scuola media statale Bramante",
            logo: logo4,
            link: "https://www.instagram.com/_gossipbramante_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        }, 
        {
            name: "Spotted Besozzi",
            description: "Un nuovo profilo gossip di Vigevano, della scuola media statale Besozzi",
            logo: logo5,
            link: "https://www.instagram.com/spotted_besozzi24?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        },
        {
            name: "NGL Mortara",
            description: "Un nuovo profilo gossip di Mortara, che ci accomagnerà e ci aiuterà nel nostro profilo",
            logo: logo6,
            link: "https://www.instagram.com/ngl_27036?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        }
    ];

    return (
        <Container className="my-5">
            <h1 style={{ fontFamily: 'Inter, sans-serif', animation: 'fadeInDown 1s' }}>I nostri partner</h1>
            <Row>
                {partners.map((partner, index) => (
                    <Col key={index} md={4} className="mb-4 d-flex">
                        <div className="partner-card p-3 d-flex flex-row align-items-center" style={{ animation: 'zoomIn 1s' }}>
                            <Image src={partner.logo} roundedCircle alt={`${partner.name} Logo`} className="mr-4" style={{ width: '80px', height: '80px', marginRight: '20px' }} />
                            <div style={{ paddingLeft: '20px' }}>
                                <h3 style={{ color: 'black' }}>{partner.name}</h3>
                                <p style={{ color: 'black' }}>{partner.description}</p>
                                <Button variant="primary" href={partner.link} target="_blank" className="mt-auto d-flex align-items-center">
                                    <FaInstagram className="mr-2" /> Visita il Profilo Instagram
                                </Button>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <style jsx>{`
                @keyframes fadeInDown {
                    from {
                        opacity: 0;
                        transform: translateY(-20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                @keyframes zoomIn {
                    from {
                        opacity: 0;
                        transform: scale(0.5);
                    }
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                body {
                    background-color: #001f3f;
                    color: white;
                }

                .partner-card {
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 16px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 20px;
                }

                @media (max-width: 768px) {
                    .partner-card {
                        flex-direction: column;
                        text-align: center;
                    }
                }
            `}</style>
        </Container>
    );
}

export default Partnership;





