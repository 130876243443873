import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { Carousel, Image, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Inizializza Parse

const ReactCarousel = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const PhotoData = Parse.Object.extend('PhotoData');
      const query = new Parse.Query(PhotoData);
      try {
        const results = await query.find();
        setPhotos(results.map(photo => photo.get('imageUrl')));
      } catch (error) {
        console.error('Error while fetching photos', error);
      }
    };

    fetchPhotos();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ backgroundColor: '#003366', color: '#FFFFFF', borderRadius: '16px', padding: '20px', border: '2px solid #FFFFFF', maxWidth: '80%' }}>
        <h1 style={{ fontFamily: 'Inter, sans-serif', textAlign: 'center' }}>Le foto dell'Archivio fotografico</h1>
        <p style={{ fontFamily: 'Inter, sans-serif', textAlign: 'center' }}>Scopri l'archivio delle foto di proprietà di NGL, consultando la sezione galleria nella nostra navbar!</p>
        <Container className="d-flex justify-content-center align-items-center">
          <Carousel className="w-100" style={{ maxHeight: '60vh' }}>
            {photos.map((url, index) => (
              <Carousel.Item key={index}>
                <Image
                  src={url}
                  alt={`Slide ${index}`}
                  fluid
                  rounded
                  style={{ width: '100%', height: 'auto', borderRadius: '16px', objectFit: 'cover', maxHeight: '60vh' }}
                  className="carousel-image"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </div>
      <style jsx>{`
        @media (max-width: 768px) {
          .carousel-image {
            width: 100% !important;
            height: auto !important;
          }
        }
      `}</style>
    </div>
  );
};

export default ReactCarousel;







