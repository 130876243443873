import React, { useState, useEffect } from "react";
import { Form, Button, Container, Image } from "react-bootstrap";
import { FaCircle, FaUser, FaInstagram, FaTshirt, FaShoppingCart, FaCreditCard } from "react-icons/fa";
import Navbarngl from "./navbarngl";
import maglia from './contents/maglia.jpg';
import Parse from "parse";
import Tops from "./top";

const colorOptions = [
    { value: 'blu scuro', label: 'Blu Scuro', color: '#00008B' },
    { value: 'rosso', label: 'Rosso', color: '#FF0000' },
    { value: 'verde', label: 'Verde', color: '#008000' },
    // Aggiungi altre opzioni di colore qui
];

const MaglietteLayout = () => {
    const [formData, setFormData] = useState({
        nome: '',
        nomeig: '',
        taglia: 'M',
        quantita: '',
        colore: 'blu scuro',
        pagamento: 'online'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const Acquisto = Parse.Object.extend("Acquisto");
            const acquisto = new Acquisto();

            acquisto.set("nome", formData.nome);
            acquisto.set("nomeig", formData.nomeig);
            acquisto.set("taglia", formData.taglia);
            acquisto.set("quantita", formData.quantita);
            acquisto.set("colore", formData.colore);
            acquisto.set("pagamento", formData.pagamento);

            await acquisto.save();
            alert('Dati salvati con successo!');
        } catch (error) {
            console.error('Errore nel salvataggio:', error);
            alert('Errore nel salvataggio dei dati.');
        }
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.paypal.com/sdk/js?client-id=ARBNBvCyXJPQklsTQFrQPGGZE8HNH42o4STLFZl3gkH-c_e3WP2W2SCLcBd_H4swfyeQYTBAxY9sY30t&currency=EUR";
        script.async = true;
        script.onload = () => {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: '8.49' // Costo della maglietta
                            }
                        }]
                    });
                },
                onApprove: async (data, actions) => {
                    return actions.order.capture().then(async (details) => {
                        try {
                            const Acquisto = Parse.Object.extend("Acquisto");
                            const acquisto = new Acquisto();
    
                            acquisto.set("nome", formData.nome);
                            acquisto.set("nomeig", formData.nomeig);
                            acquisto.set("taglia", formData.taglia);
                            acquisto.set("quantita", formData.quantita);
                            acquisto.set("colore", formData.colore);
                            acquisto.set("pagamento", formData.pagamento);
                            acquisto.set("transactionId", details.id); // Salva l'ID della transazione PayPal
    
                            await acquisto.save();
                            alert('Pagamento completato con successo!');
                        } catch (error) {
                            console.error('Errore nel salvataggio:', error);
                            alert('Errore nel salvataggio dei dati.');
                        }
                    });
                }
            }).render('#paypal-button-container');
        };
        script.onerror = () => {
            console.error("Error loading PayPal script");
        };
        document.body.appendChild(script);
    }, [formData]);
    


    return (
        <div style={{ fontFamily: 'Inter, sans-serif' }}>
            <Navbarngl />
            <Container className="form-container">
                <h1>Acquista la maglia di NGL Cila</h1>
                <Image src={maglia} fluid />
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formNome">
                        <Form.Label style={{ display: 'flex', alignItems: 'center' }}>
                            <FaUser style={{ marginRight: '10px' }} /> Nome
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="nome"
                            value={formData.nome}
                            onChange={handleChange}
                            placeholder="Inserisci il tuo nome"
                        />
                    </Form.Group>
                    <Form.Group controlId="formNomeInstagram">
                        <Form.Label style={{ display: 'flex', alignItems: 'center' }}>
                            <FaInstagram style={{ marginRight: '10px' }} /> Nome Instagram
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="nomeig"
                            value={formData.nomeig}
                            onChange={handleChange}
                            placeholder="Inserisci il tuo nome instagram"
                        />
                    </Form.Group>

                    <Form.Group controlId="formTaglia">
                        <Form.Label style={{ display: 'flex', alignItems: 'center' }}>
                            <FaTshirt style={{ marginRight: '10px' }} /> Taglia
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="taglia"
                            value={formData.taglia}
                            onChange={handleChange}
                        >
                            <option value="XS">XS</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formColore">
                        <Form.Label style={{ display: 'flex', alignItems: 'center' }}>
                            <FaCircle style={{ marginRight: '10px' }} /> Colore
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="colore"
                            value={formData.colore}
                            onChange={handleChange}
                        >
                            {colorOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formQuantita">
                        <Form.Label style={{ display: 'flex', alignItems: 'center' }}>
                            <FaShoppingCart style={{ marginRight: '10px' }} /> Quantità
                        </Form.Label>
                        <Form.Control
                            type="number"
                            name="quantita"
                            value={formData.quantita}
                            onChange={handleChange}
                            placeholder="Inserisci la quantità"
                        />
                    </Form.Group>

                    <Form.Group controlId="formPagamento">
                        <Form.Label style={{ display: 'flex', alignItems: 'center' }}>
                            <FaCreditCard style={{ marginRight: '10px' }} /> Metodo di Pagamento
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="pagamento"
                            value={formData.pagamento}
                            onChange={handleChange}
                        >
                            <option value="online">Online</option>
                            <option value="fisico">Fisico</option>
                        </Form.Control>
                    </Form.Group>

                    {formData.pagamento === 'fisico' && (
                        <Button variant="primary" type="submit" style={{ marginTop: '10px', marginBottom: '10px' }}>
                            Ordina
                        </Button>
                    )}
                </Form>
                {formData.pagamento === 'online' && (
                    <div id="paypal-button-container" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}></div>

                )}
            </Container>
            <Tops />
        </div>
    );
};

export default MaglietteLayout;


















