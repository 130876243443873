import React, { useEffect, useState } from 'react';
import { Navbar, Container, Row, Col, Card } from 'react-bootstrap';
import Parse from 'parse';
import ReactPlayer from 'react-player';
import Navbarngl from './navbarngl';
import AddNewsForm from './addnewsform';
import sfondo from './contents/sfondo.png';
import Tops from './top';
import UserAccessData from './geolocalizzazione';

function News() {
  const [newsList, setNewsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const News = Parse.Object.extend('News');
        const query = new Parse.Query(News);
        const results = await query.find();
        setNewsList(results);
      } catch (error) {
        console.error('Error fetching news:', error);
        // Puoi mostrare un messaggio di errore all'utente qui
      }
    };

    fetchNews();
  }, []);

  const filteredNewsList = newsList.filter((newsItem) =>
    newsItem.get('title').toLowerCase().includes(searchTerm.toLowerCase()) ||
    newsItem.get('content').toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortedNewsList = filteredNewsList.sort((a, b) => new Date(b.get('date')) - new Date(a.get('date')));

  return (
    <div className="News" style={{ background: 'linear-gradient(to bottom, #00008B, #87CEEB)', paddingBottom: '20px' }}>
      <Navbarngl />
      <UserAccessData />
      <h1 style={{ color: 'white', textAlign: 'center', fontStyle: 'bold', fontFamily: 'Inter, sans-serif' }}>Notizie</h1>
      <Container>
        <Row className="justify-content-center mb-3">
          <Col md={6}>
            <input 
              type="text"
              placeholder="Cerca notizie..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '100%', padding: '10px', borderRadius: '5px' }}
            />
          </Col>
        </Row>
        <Row className="g-2 mb-4">
  {sortedNewsList.map((newsItem) => (
    <Col md={4} className="d-flex align-items-stretch" key={newsItem.id}>
      <Card style={{ width: '100%', height: 'auto', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        {newsItem.get('mediaType') === 'image' ? (
          <Card.Img variant="top" src={newsItem.get('media').url()} style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px', objectFit: 'cover' }} />
        ) : (
          <ReactPlayer
            url={newsItem.get('media').url()}
            controls
            width="100%"
            height="100%"
            style={{ borderTopLeftRadius: '15px', borderTopRightRadius: '15px', objectFit: 'cover' }}
          />
        )}
        <Card.Body>
          <Card.Title style={{ fontFamily: 'Inter, sans-serif' }}>{newsItem.get('title')}</Card.Title>
          <Card.Text style={{ fontFamily: 'Inter, sans-serif' }}>{newsItem.get('content')}</Card.Text>
        </Card.Body>
      </Card>
    </Col>
  ))}
</Row>


      </Container>
      <Tops />
    </div>
  );
}

export default News;



