import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import { Container, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const InstagramProfile = () => {
  return (
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f8f9fa', padding: '0 20px' }}>
      <Card style={{ width: '100%', maxWidth: '500px', padding: '20px', backgroundColor: 'white', color: 'black', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Card.Header style={{ textAlign: 'center', marginBottom: '20px', fontSize: '1.5em', fontWeight: 'bold' }}>
          Dai un'occhiata al profilo Instagram di Ngl_cila
        </Card.Header>
        <Card.Body>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <InstagramEmbed url="https://www.instagram.com/ngl_cila/" width="100%" maxWidth="320px" />
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InstagramProfile;




