import React from 'react';
import { Card, Button, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './contents/esploranglvige.css';
import nglvige from './contents/nglvige.png'

const ExploraNglVige = () => {
  return (
   <Container>
    <Card className="explora-card">
      <Card.Header as="h1" className="explora-title">ESPLORA NGL VIGE</Card.Header>
      <Card.Body>
        <div className="explora-logo">
          <img src={nglvige} alt="Logo" className="explora-img"/>
        </div>
        <Card.Text style={{color: 'white'}}>
          Questa è una pagina rivolta ai ragazzi e alle ragazze di Vigevano come profilo gossip e spotted. Gestito da noi e da persone specializzate in quest'ambito
        </Card.Text>
        <Button variant="primary" as='a' href='https://www.instagram.com/ngl_vigee?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='>Scopri di più</Button>
      </Card.Body>
    </Card>
    </Container>
  );
};

export default ExploraNglVige;

